.slide{
    width: calc(21rem * 7);
    animation: slideLoop 20s linear infinite;
  }

.hover{
  background-size: 100%;
  transition: background-size .25s ease;
}

.hover:hover{
  background-size: 110%;
}

.bg-img-diseases{
  background-image: url('../../public/treatment.jpeg');
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 1023px) {
  .slide{
    width: calc(16rem * 7);
    animation: slideLoop 20s linear infinite;
  }

  .hover{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 24rem;
  }
  
  .hover:hover{
    background-size: cover;
  }
}
  
@keyframes slideLoop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}